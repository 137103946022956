import React, { createContext, useState, useEffect, useRef } from 'react';
import { useWindowSize } from 'hooks';

const ThemeContext = createContext();

const ThemeProvider = (props) => {
  const theme = {
    main: 'rgb(150, 30, 50)',
    secondary: 'rgba(100, 100, 50,.75)',
    muted: 'rgba(150, 30, 50,.75)',
    light: 'rgb(255,250,250)'
  };
  const headerRef = useRef(null);
  const aboutRef = useRef(null);

  const scrollTo = (ref) =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  const { windowWidth } = useWindowSize();

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (windowWidth > 1150) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [isMobile, windowWidth]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        isMobile,
        headerRef,
        aboutRef,
        scrollTo
      }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider };
export default ThemeContext;
