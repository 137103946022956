import * as React from 'react';

function SvgLogo(props) {
  return (
    <svg viewBox="0 0 500 67" fill="none" {...props}>
      <path
        d="M69.998 0h6.614l24.251 60.445L125.207 0h6.614l-27.375 66.875h-7.073L69.998 0zM240.768 9.186v57.689h-5.695V0h5.787l41.889 56.954V0h5.695v66.875h-5.603L240.768 9.186zM340.07 5.236h-21.863V0h49.513v5.236h-21.863v61.639h-5.787V5.236zM44.553.093H0v5.236h44.553V.093zM33.162 28.936H0v5.236h30.865l2.297-5.236zM44.553 61.73H0v5.237h44.553V61.73zM205.218.092h-44.553v5.236h44.553V.092zM193.827 28.937h-33.162v5.236h30.866l2.296-5.236zM205.218 61.731h-44.553v5.236h44.553v-5.236z"
        fill="#000"
      />
      <path
        d="M469.41 27.834l-30.406 38.95h7.165L469.41 37.02l23.425 29.855H500l-30.59-39.041z"
        fill="#EB0045"
      />
      <path
        d="M475.932 28.753L498.438 0h-7.349L472.35 24.068l3.582 4.684zM466.563 24.068L447.731 0h-7.257l22.506 28.753 3.583-4.685z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgLogo;
