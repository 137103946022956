import * as React from 'react';

function SvgUser(props) {
  return (
    <svg viewBox="0 0 70 80" fill="none" {...props}>
      <path
        d="M47.217 34.206c4.623-3.517 7.594-8.951 7.594-15.025C54.811 8.63 45.896 0 35 0S15.189 8.631 15.189 19.18c0 6.075 2.971 11.51 7.594 15.026C9.575 39 0 51.468 0 65.854c0 18.861 70 18.861 70 0 0-14.386-9.245-26.853-22.783-31.648z"
        fill="#007FC0"
      />
    </svg>
  );
}

export default SvgUser;
