import React, { useContext, useRef } from 'react';
import { Link } from 'gatsby';
import { Modal, ContactForm } from 'components';
import styled from 'styled-components';
import { LogoContrast, FacebookIcon, TwitterIcon, LinkedinIcon, InstaIcon, MobIcon, LocationIcon  } from 'assets/svgs';
import { ThemeContext } from 'context';

export default () => {

  const {
    isMobile,
    headerRef,
    contactRef,
    scrollTo
  } = useContext(ThemeContext);
  return (
    <StyledFooter>
        <div className="container">
        <div className="grid">
          <SocialColumn>
            <h4>FIND US ON</h4>
            <Socials>
              <Social href="https://www.facebook.com/" target="blank">
                <FacebookIcon />
              </Social>
              <Social href="https://twitter.com/" target="blank">
                <TwitterIcon />
              </Social>
              <Social href="https://www.linkedin.com/company/" target="blank">
                <LinkedinIcon />
              </Social>
              <Social href="https://www.instagram.com/" target="blank">
                <InstaIcon />
              </Social>
            </Socials>
          </SocialColumn>
          <ContactColumn>
            <div id="contact" ref={contactRef} />
            {!isMobile && (
              <LogoContainer>
                <p>Hosted by</p>
                <LogoContrast />
              </LogoContainer>
            )}
            <h4>Contacts</h4>

            <Contact href="tel: 000 000">
              <ContactIcon>
                <MobIcon />
              </ContactIcon>
              <span>Your Phone Number</span>
            </Contact>
            <Contact
              target="blank"
              href="/">
              <ContactIcon>
                <LocationIcon />
              </ContactIcon>
              Your Company Address, Dublin, Ireland
            </Contact>

          </ContactColumn>
          {!isMobile && (
            <SitemapColumn>
              <h4>sitemap</h4>
              <Link
                to="#header"
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo(headerRef);
                }}>
                Home
              </Link>
            </SitemapColumn>
          )}

          {!isMobile && (
            <SupportColumn>
              <h4>support</h4>
              <Modal
                trigger={<button type="button">Contact Us</button>}
                modalContent={<ContactForm />}
              />
              <a href="/">
                Legal & Privacy
              </a>
            </SupportColumn>
          )}
        </div>
      </div>
      <div className="container">
        <FooterCopy>
          {isMobile && (
            <>
              <a href="/">
                Legal & Privacy
              </a>
              <p>Hosted by</p>
              <LogoContrast />
            </>
          )}

          <p>
            Copyright and other relevant information goes here Limited, all rights reserved.
          </p>
        </FooterCopy>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.9);
  padding: 0.625rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  @media (min-width: 600px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    padding: 0.625rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .grid {
    grid-template-areas: 'demo' 'socials' 'contact' 'footercopy';
    @media (min-width: 1150px) {
      grid-template-areas: 'demo demo help help' 'contact socials sitemap support' '. footercopy footercopy .';
      grid-row-gap: 6rem;
    }
  }
  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
    color: white;
    margin-bottom: 1rem;
    letter-spacing: 0.1em;
    text-align: left;
    text-transform: uppercase;
  }
`;

const SocialColumn = styled.div`
  grid-area: socials;
  display: block;
  flex-direction: column;
  grid-column: 1/6;
  margin-bottom: 3rem;
  @media (min-width: 1150px) {
    grid-column: 9/12;
    margin-bottom: 0;
  }
  @media (min-width: 1440px) {
    grid-column: 10/12;
    margin-bottom: 0;
  }
`;

const Socials = styled.div`
  grid-area: socials;
  display: flex;
`;

const Social = styled.a`
  background: var(--main);
  border-radius: 50%;
  min-width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  transition: 0.75s;
  svg {
    width: auto;
    height: auto;
    max-width: 20px;
    max-height: 20px;
    width: 22px;
    transition: 0.75s;
    path {
      fill: white;
    }
  }
  :hover {
    background: var(--light);
    svg {
      path {
        fill: var(--main);
      }
    }
  }
`;

const ContactColumn = styled.div`
  grid-area: contact;
  display: flex;
  flex-direction: column;
  grid-column: 1/6;
  margin-bottom: 3rem;
  align-items: flex-start;
  h4 {
    text-transform: capitalize;
  }
  @media (min-width: 1150px) {
    margin-bottom: 0;
    grid-column: 2/5;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 2rem;
  p{
    margin-bottom:1rem;
  }
  svg {
    margin-left:-.5rem;
    max-height: 1.5rem;
    width: 200px;
  }
`;

const Contact = styled.a`
  display: flex;
  margin-bottom: 1rem;
  color: white;
  font-size: 1.125rem;

  :visited {
    color: white;
  }
`;

const ContactIcon = styled.div`
  background: var(--main);
  border-radius: 50%;
  min-width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  transition: 0.75s;
  svg {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
    transition: 0.75s;
    path {
      stroke: white;
    }
  }
  ${Contact}:hover & {
    background: var(--light);
    svg {
      path {
        stroke: var(--main);
      }
    }
  }
`;

const FooterCopy = styled.div`
  grid-area: footercopy;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.625rem;
  a {
    margin-bottom: 2.5rem;
    color: white;
    font-size: 1.125rem;
    line-height: 2rem;

    :visited {
      color: white;
    }
  }
  svg {
    height: 1.5rem;
    width: auto;
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25em;
    margin-bottom:1rem;
    color: white;
  }
  @media (min-width: 1150px) {
    align-items: center;
  }
`;

const SitemapColumn = styled.div`
  grid-area: sitemap;
  grid-column: 5/7;
  margin-bottom: 3rem;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  @media (min-width: 1440px) {
    grid-column: 6/8;
    margin-bottom: 0;
  }
  a {
    color: white;
    font-size: 1rem;
    line-height: 2em;
    :visited {
      color: white;
    }
  }
`;

const SupportColumn = styled.div`
  grid-area: support;
  grid-column: 7/9;
  margin-bottom: 3rem;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  color: white;
  @media (min-width: 1440px) {
    grid-column: 8/10;
    margin-bottom: 0;
  }
  button {
    background: none;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    line-height: 2em;
    cursor: pointer;
  }
  a {
    color: white;
    font-size: 1rem;
    line-height: 2em;
    :visited {
      color: white;
    }
  }
`;
