import * as React from 'react';

function SvgLogoContrast(props) {
  return (
    <svg viewBox="0 0 500 67" fill="none" {...props}>
      <g clipPath="url(#logo-contrast_svg__clip0)">
        <path
          d="M69.684 0h6.585l24.143 60.174L124.646 0h6.584l-27.252 66.575h-7.041L69.683 0zM239.689 9.145v57.43h-5.67V0h5.762l41.7 56.699V0h5.67v66.575h-5.578l-41.884-57.43zM338.546 5.213h-21.764V0h49.291v5.213h-21.764v61.362h-5.763V5.213zM44.353.092H0v5.212h44.353V.092zM33.013 28.807H0v5.213h30.727l2.286-5.213zM44.353 61.454H0v5.213h44.353v-5.213zM204.298.092h-44.353v5.212h44.353V.092zM192.959 28.807h-33.014v5.213h30.727l2.287-5.213zM204.298 61.454h-44.353v5.213h44.353v-5.213z"
          fill="#fff"
        />
        <path
          d="M467.306 27.71l-30.27 38.774h7.133l23.137-29.63 23.321 29.721h7.133L467.306 27.71z"
          fill="#EB0045"
        />
        <path
          d="M473.8 28.624L496.203 0h-7.315l-18.655 23.96 3.567 4.664zM464.473 23.96L445.724 0H438.5l22.406 28.624 3.567-4.664z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="logo-contrast_svg__clip0">
          <path fill="#fff" d="M0 0h500v66.667H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLogoContrast;
