import React, { useContext } from 'react';
import { Header, SEO } from 'components';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ThemeContext } from 'context';
import { User } from 'assets/svgs';

export default () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Speakers>
      <SpeakerDetails theme={theme}>
        <SvgBackground theme={theme}>
          <User />
        </SvgBackground>
        <h4>Speaker One</h4>
        <p>Speaker one's information will be outlined here.</p>
      </SpeakerDetails>
      <SpeakerDetails theme={theme}>
        <SvgBackground theme={theme}>
          <User />
        </SvgBackground>
        <h4>Speaker Two</h4>
        <p>Speaker two's information will be outlined here.</p>
      </SpeakerDetails>
      <SpeakerDetails theme={theme}>
        <SvgBackground theme={theme}>
          <User />
        </SvgBackground>
        <h4>Speaker Three</h4>
        <p>Speaker three's information will be outlined here.</p>
      </SpeakerDetails>
    </Speakers>
  );
};

const SvgBackground = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: white;
  border: ${({ theme }) => `2px solid ${theme.main}`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  svg {
    width: 3rem;
    height: 3rem;
    path {
      fill: ${({ theme }) => theme.main};
    }
  }
`;

const SpeakerDetails = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 0.5rem;
  padding:1rem;
  &:hover {
    background: #ededed;
  }
  h4 {
    color: ${({ theme }) => theme.main};
    font-size: 1.25rem;
    letter-spacing: 1px;
    margin-bottom: 0.5em;
  }
  p{
      margin-bottom:0;
  }
  @media (min-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Speakers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  grid-column-gap: 3rem;
  justify-content: center;
  align-items: start;
  margin: 1.5rem auto;
  @media (min-width: 768px) {
    grid-template-columns: 175px 175px 175px;
    grid-column-gap:1rem;
  }
  @media (min-width: 1150px) {
    grid-gap: 3rem;
    grid-template-columns: 225px 225px 225px;
  }
`;
