import React from 'react';
import styled from 'styled-components';
import { Chrome, Firefox, Edge, Safari } from 'assets/svgs';

export default () => (
  <Wrapper>
    <Modal>
      <h1>Browser Is Not Supported</h1>
      <p>
        It looks like you&apos;re using Internet Explorer, which is an old browser no longer
        supported by Campaign X and many other modern websites. For the best online browsing
        experience use Chrome, Firefox, Edge or Safari.
      </p>
      <BrowserLogos>
        <a href="https://www.google.com/chrome/">
          <Logo>
            <Chrome />
          </Logo>
        </a>
        <a href="https://www.mozilla.org/en-US/firefox/new/">
          <Logo>
            <Firefox />
          </Logo>
        </a>
        <a href="https://www.microsoft.com/en-us/edge">
          <Logo>
            <Edge />
          </Logo>
        </a>
        <a href="https://support.apple.com/downloads/safari">
          <Logo>
            <Safari />
          </Logo>
        </a>
      </BrowserLogos>
      <p>
        To learn more about why websites are dropping
        <br />
        support for Internet Explorer&nbsp;
        <a
          href="https://element5digital.com/why-wont-support-internet-explorer-and-neither-should-you/"
          target="__blank"
          style={{
            color: '#0090de'
          }}>
          click here
        </a>
        .
      </p>
    </Modal>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: #009245;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: proxima-nova, sans-serif;
  align-items: center;
  position: relative;
  top: -0.5rem;
  left: -0.5rem;
`;

const BrowserLogos = styled.div`
  max-width: 35.375rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Logo = styled.div`
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 5.75rem;
  }
`;

const Modal = styled.div`
  align-items: center;
  background-color: #eeeeee;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 32.5rem;
  width: 53.5rem;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 2.75em 0;
  h1 {
    font-size: 2rem;
    margin: 0.25em auto 0;
  }
  p {
    line-height: 1.5rem;
    width: 35.375rem;
    text-align: center;
    margin: 0;
    &:first-of-type {
      margin-bottom: 0.75em;
    }
  }
`;
