import React, { useContext } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Navbar, Footer, IENotSupportedSplash } from 'components';
import { isIE } from 'react-device-detect';
import { ThemeContext } from 'context';

const GlobalStyles = createGlobalStyle`
  :root {
    --main: ${({ theme }) => theme.main};
    --light: ${({ theme }) => theme.light};
    --muted: ${({ theme }) => theme.muted};
    --secondary: ${({ theme }) => theme.secondary};
  }
* {
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family: proxima-nova, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html{
  font-size: 16px;
  overflow-x:hidden;
}
body{
  background: white;
  overflow-x:hidden;
}
h1,h2,h3,h4,h5,h6{
  margin-block-start:0;
  margin-block-end:0;
  text-align:center;
  width:100%;
}
p{
  margin-block-start:0;
  margin-block-end:0;
}

h3{
  font-size: 1.5rem;
  line-height:1.25em;
  text-align:center;
  width:100%;
  letter-spacing:0.1em;
  color: var(--main);
  @media (min-width:1150px){
    font-size:2rem;
    line-height:1.25em;
  }
}
h4{

}
button{
  border:none;
  outline:none;
  :focus, :hover{
      outline:none;
      border:none;
  }
}
a{
  text-decoration:none;
}

.container{
  max-width:1440px;
  width:100%;
  margin: 0 auto;
}
.grid{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0.625rem;
  color: var(--main);
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1.25rem;
  }

}
  .scroll-lock {
    overflow: hidden;
  }
`;

const Layout = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  return !isIE ? (
    <>
      <GlobalStyles theme={theme} />
      <Navbar />
      <main>
        {children}
        <Footer />
      </main>
    </>
  ) : (
    <IENotSupportedSplash />
  );
};

export default Layout;
