import React, { useContext, useEffect, useState } from 'react';
import { Header, SEO, Speakers } from 'components';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeContext } from 'context';
import { useIntersection } from 'hooks';

const Section = ({ className, bg, children }) => {
  const { observerEntry, elRef } = useIntersection({ threshold: 0.25 });
  return (
    <StyledSection className={className} bg={bg}>
      <MotionContainer
        initial={{ opacity: 0, y: 50 }}
        animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
        ref={elRef}>
        {children}
      </MotionContainer>
    </StyledSection>
  );
};

const Home = () => {
  const [isVimeoEventShown, setIsVimeoEventShown] = useState(false);
  const [isLiveWebinarEventShown, setIsLiveWebinarEventShown] = useState(false);

  const { theme, headerRef, aboutRef } = useContext(ThemeContext);
  return (
    <>
      <SEO pageSpecificTitle="Home" />
      <div id="header" ref={headerRef} />
      <Header />
      <Section>
        <div className="container">
          <div id="about" ref={aboutRef} style={{ position: 'absolute', top: '-150px' }} />
          <div className="grid">
            <Pitch>
              Re-imagine your events as online shows, your customers as your audience, and their
              attention as your competitive advantage.
            </Pitch>
          </div>
        </div>
      </Section>
      {/* <Section>
        <Container>
          <div id="embedWidget" style={{width:"100%"}}></div>
        </Container>
      </Section> */}
      <Section bg="rgba(239, 237, 228, 0.3)">
        <Container>
          <h3>Event Details</h3>
          <p>
            When you're ready to launch your event, the link below will become active and direct the
            user to the meeting/webinar within your chosen platform.
          </p>
          <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
            <button type="button">
              <CustomLink
                theme={theme}
                to="#about"
                onClick={(e) => {
                  e.preventDefault();
                  if(isLiveWebinarEventShown) {
                    setIsLiveWebinarEventShown(false)
                  }
                  setIsVimeoEventShown((currentValue) => !currentValue);
                  // scrollTo(aboutRef);
                }}>
                {isVimeoEventShown ? 'HIDE VIMEO EVENT' : 'SHOW VIMEO EVENT'}
              </CustomLink>
            </button>
            <button type="button">
              <CustomLink
                theme={theme}
                to="#about"
                style={{ width: '270px' }}
                onClick={(e) => {
                  e.preventDefault();
                  if(isVimeoEventShown) {
                    setIsVimeoEventShown(false)
                  }
                  setIsLiveWebinarEventShown((currentValue) => !currentValue);
                  // scrollTo(aboutRef);
                }}>
                {isLiveWebinarEventShown ? 'HIDE LIVEWEBINAR EVENT' : 'SHOW LIVEWEBINAR EVENT'}
              </CustomLink>
            </button>
          </div>
          <AnimatePresence>
            {isVimeoEventShown && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ maxWidth: '1100px', width: '100%', margin: '3rem' }}>
                <div
                  style={{
                    padding: '56.25% 0 0 0',
                    height: '100%',
                    position: 'relative',
                    width: '100%'
                  }}>
                  <iframe
                    title="Vimeo Test"
                    src="https://vimeo.com/event/703461/embed"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {isLiveWebinarEventShown && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ maxWidth: '1100px', width: '100%', margin: '3rem' }}>
                <div id="embedWidget" style={{ width: '100%' }} />
                <script>
                  {(() => {
                    var _options = {
                      _license_key: '294-269-188',
                      _role_token: '',
                      _registration_token: '',
                      _widget_containerID: 'embedWidget',
                      _widget_width: '100%',
                      _widget_height: '100vh'
                    };
                    (function () {
                      !(function (i) {
                        (i.Widget = function (c) {
                          'function' == typeof c && i.Widget.__cbs.push(c),
                            i.Widget.initialized &&
                              (i.Widget.__cbs.forEach(function (i) {
                                i();
                              }),
                              (i.Widget.__cbs = []));
                        }),
                          (i.Widget.__cbs = []);
                      })(window);
                      var ab = document.createElement('script');
                      ab.type = 'text/javascript';
                      ab.async = true;
                      ab.src =
                        'https://embed.livewebinar.com/em?t=' +
                        _options['_license_key'] +
                        '&' +
                        Object.keys(_options)
                          .reduce(function (a, k) {
                            a.push(k + '=' + encodeURIComponent(_options[k]));
                            return a;
                          }, [])
                          .join('&');
                      var s = document.getElementsByTagName('script')[0];
                      s.parentNode.insertBefore(ab, s);
                    })();
                  })()}
                </script>
              </motion.div>
            )}
          </AnimatePresence>
        </Container>
      </Section>
      <Section>
        <Container>
          <h3>Event Speakers</h3>
          <p>
            Your speakers will appear in the list below. An optional popup can be added for more
            information on click.
          </p>
          <Speakers />
        </Container>
      </Section>
    </>
  );
};

export default Home;

const CustomLink = styled.a`
  width: 12.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.main};
  color: white;
  border-radius: 0.25rem;
  font-size: 1rem;
  letter-spacing: 1px;
  transition: 0.375s;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  border: 1px solid ${({ theme }) => theme.main};
  &:hover {
    background: white;
    color: ${({ theme }) => theme.main};
  }
`;

const MotionContainer = styled(motion.div)`
  width: 100%;
  height: auto;
`;

const Pitch = styled(motion.h3)`
  font-size: 1.5rem;
  line-height: 1.25em;
  max-width: 750px;
  margin: 3rem auto;
  grid-column: 1/6;
  padding: 1rem;
  transition-duration: 0.75s;
  @media (min-width: 768px) and (max-width: 1150px) {
    margin: 0 auto;
  }

  @media (min-width: 1150px) {
    grid-column: 3/11;
    margin: 1rem auto;
  }
`;

const StyledSection = styled(motion.section)`
  background: ${({ bg }) => bg};
  width: 100%;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition-duration: 1s;
  @media (min-width: 600px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    padding: 2rem;
  }
`;

const Container = styled.div`
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
  h3 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 2rem;
    max-width: 750px;
    text-align: center;
    line-height: 1.5em;
  }
  @media (min-width: 768px) {
    padding: 3rem;
  }
`;
