import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import { Logo, FacebookIcon, TwitterIcon, LinkedinIcon, InstaIcon } from 'assets/svgs';
import { Modal, ContactForm } from 'components';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeContext } from 'context';
import { useWindowSize } from 'hooks';

export default () => {
  const route = typeof window !== 'undefined' ? window.location.pathname : '';
  const { theme, scrollTo, headerRef, aboutRef } = useContext(ThemeContext);
  const { windowWidth } = useWindowSize();
  const isMobile = windowWidth < 768;
  const [navOpen, setNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(route !== '' ? route : '/');

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  return (
    <Navbar theme={theme} scrolled={isScrolled} isMobile={isMobile}>
      <LogoButton
        onClick={() => {
          scrollTo(headerRef);
        }}>
        <StyledLogo />
      </LogoButton>
      <AnimatePresence>
        {(!isMobile || navOpen) && (
          <Links
            navOpen={navOpen}
            initial={isMobile ? { opacity: 0, x: 400 } : { opacity: 0, y: -50 }}
            animate={isMobile ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }}
            exit={isMobile ? { opacity: 0, x: 400 } : { opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}>
            <div>
              <CustomLink
                className={activeLink === '/home/' ? 'active ' : null}
                to="#Home"
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo(headerRef);
                  toggleActiveLink('/home/');
                }}>
                Home
              </CustomLink>
              <CustomLink
                className={activeLink === '/about/' ? 'active ' : null}
                to="#about"
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo(aboutRef);
                  toggleActiveLink('/about/');
                }}>
                About
              </CustomLink>
              <CustomLink
                className={activeLink === '/demo/' ? 'active ' : null}
                to="#demo"
                onClick={(e) => {
                  e.preventDefault();
                }}>
                <Modal trigger="Contact" modalContent={<ContactForm />} />
              </CustomLink>
            </div>
            {isMobile && (
              <Socials>
                <Social href="https://www.facebook.com/" target="blank">
                  <FacebookIcon />
                </Social>
                <Social href="https://twitter.com/" target="blank">
                  <TwitterIcon />
                </Social>
                <Social href="https://www.linkedin.com/company/" target="blank">
                  <LinkedinIcon />
                </Social>
                <Social href="https://www.instagram.com/" target="blank">
                  <InstaIcon />
                </Social>
              </Socials>
            )}
          </Links>
        )}
      </AnimatePresence>
      {isMobile && (
        <MenuToggle navOpen={navOpen} onClick={toggleNav}>
          <span />
        </MenuToggle>
      )}
    </Navbar>
  );
};

const Navbar = styled.nav`
  width: 1440px;
  max-width: 100%;
  height: 60px;
  display: grid;
  grid-template-areas: 'logo burger' 'links links';
  align-items: center;
  justify-content: space-between;
  background: var(--light);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  z-index: 3;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
  @media (min-width: 768px) {
    grid-template-areas: 'logo links';
    height: ${({ isScrolled }) => (isScrolled ? '60px' : '80px')};
  }
`;

const LogoButton = styled.button`
  height: 100%;
  width: 200px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;

const StyledLogo = styled(Logo)`
  max-height: 1.25rem;
  margin-left: 0.625rem;
  width: auto;
  grid-area: logo;
  @media (min-width: 768px) {
    margin-left: 2.5rem;
  }
`;

const Links = styled(motion.div)`
  grid-area: links;
  display: ${({ navOpen }) => (navOpen ? 'flex' : 'none')};
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  height: calc(100vh - 60px);
  background: var(--main);
  backdrop-filter: blur(2px);
  padding: 3rem 2rem;
  > div {
    &:first-child {
      width: 100%;
      height: auto;
    }
  }
  @media (min-width: 768px) {
    padding: 0;
    display: flex;
    flex-direction: row;
    height: 100%;
    background: transparent;
    backdrop-filter: none;
    > div {
    &:first-child {
      width: 100%;
      height: 100%;
      display:flex;
    }
  }
  }
`;

const CustomLink = styled(Link)`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1em;
  color: black;
  letter-spacing: 0.1rem;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  margin: 0.5em 0;
  text-align: center;
  div {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) {
    font-size: 1.1rem;
    padding-right: 2.5rem;
    height: 100%;
    width: auto;
    color: black;
    justify-content: center;
    margin: 0;
    :first-child {
      border-top: none;
    }
    :nth-child(odd) {
      background: transparent;
    }
  }
  :last-child {
    background: var(--main);
    color: white;
    padding: 0;
    @media (min-width: 768px) {
      text-transform: uppercase;
      width: 14rem;
    }
  }
`;

const MenuToggle = styled.button`
  height: 60px;
  width: 60px;
  background: ${({ navOpen }) => (navOpen ? 'rgba(0,0,0.9)' : 'var(--main)')};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  span {
    content: '';
    background: white;
    height: 3px;
    width: 20px;
    display: block;
    position: relative;
    transition: 0.5s;
    margin-top: -16px;
    transform: ${({ navOpen }) => (navOpen ? 'translateY(8px) rotate(45deg)' : 'none')};
    :before,
    :after {
      content: '';
      display: block;
      height: 3px;
      width: 20px;
      position: absolute;
      background: white;
      transition: 0.5s;
      transform-origin: center;
      opacity: 1;
    }
    :before {
      top: 8px;
      opacity: ${({ navOpen }) => (navOpen ? 0 : 1)};
    }
    :after {
      top: 16px;
      transform: ${({ navOpen }) => (navOpen ? 'translateY(-16px) rotate(-90deg)' : 'none')};
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const Socials = styled.div`
  display: flex;
  margin-top: 5rem;
`;

const Social = styled.a`
  background: black;
  border-radius: 50%;
  min-width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  transition: 0.75s;
  svg {
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    transition: 0.75s;
    path {
      fill: white;
    }
  }
  :hover {
    background: var(--light);
    svg {
      path {
        fill: var(--main);
      }
    }
  }
`;
